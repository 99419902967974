import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.webp";


export default function MenuLogo() {
  return (
<>


   {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || "/landingpage/gameart/" || "/landingpage/masters-and-bachelors-game-development-courses/" || "/landingpage/masters-and-bachelors-game-development-courses/" || "/landingpage/masters-and-bachelors-programs/" || window.location.pathname === "/landingpage/game-art-programs/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/"  ?   <Link to="/" className="mainLogo"><img
        src={logo}
        alt="Backstage Pass"
        className="logo"
        width={"250px"}
        height={"auto"}
      /> </Link>:
    <Link to="/" className="mainLogo">
      <img
        src={logo}
        alt="Backstage Pass"
        className="logo"
        width={"250px"}
        height={"auto"}
      />
    </Link>}
    </>
  );
}

